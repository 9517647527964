import React from 'react';

export default function Twitter() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 1.219c8.164 0 14.782 6.617 14.782 14.781 0 8.164-6.618 14.782-14.782 14.782S1.219 24.164 1.219 16 7.836 1.219 16 1.219zm2.842 9.24a3.028 3.028 0 00-3.03 3.03c0 .234.029.469.075.694a8.611 8.611 0 01-6.246-3.17 3.027 3.027 0 00.938 4.052 3.05 3.05 0 01-1.37-.385v.037a3.03 3.03 0 002.43 2.974 3.205 3.205 0 01-.797.103c-.197 0-.385-.019-.573-.047a3.035 3.035 0 002.833 2.1 6.067 6.067 0 01-3.761 1.295c-.253 0-.488-.01-.732-.037a8.561 8.561 0 004.652 1.36c5.572 0 8.62-4.615 8.62-8.62 0-.131 0-.262-.01-.394a6.515 6.515 0 001.52-1.566 6.075 6.075 0 01-1.745.469 3.021 3.021 0 001.332-1.67c-.581.348-1.238.6-1.923.732a3.022 3.022 0 00-2.213-.957z"
        fill="#1DA1F2"
      />
    </svg>
  );
}
